@import '../../styles/layout';
@import '../../styles/colors';

$header-padding: 20px 20px 10px;
$button-bg-color: #5b9bd4;

.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  gap: 10vh;
  padding: 20px;
}

.tableContainer {
  display: flex;
  flex: 0.5;
  width: 100%;
  height: 100%;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
  flex: 0.5;
  box-sizing: border-box;
  align-items: center;
}


.uploadButton {
  justify-content: center;
  align-content: center;
}

.dragAndDrop {
  background-color: rgba($divider-color, 0.4);
  border: 3px dotted $text-color-light;
  justify-items: center;
  align-items: center;
  text-align: center;
  width: 100%;
  padding: 10vh 0;

  .chooseFile {
    text-decoration: underline;
    &:hover {
      cursor: pointer;
    }
  }
}

.upload {
  width: 100%;
  padding: 0 6vw;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.buttonsContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;

  .clearButton {
    background-color: $background-color;
    color: $text-color-light;
  }

  .submitButton {
    background-color: $secondary-color;
    color: $background-color;
  }

  button {
    padding: 10px 20px;
    border: 1px solid $divider-color;
    border-radius: 10px;
    &:hover {
      cursor: pointer;
    }
  }
}

.file {
  padding: 15px;
  border: 1px solid $divider-color;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  color: $text-color;

  .right {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
  }

  .fileSize {
    color: $text-color-light;
  }
  
  .removeFileButton {
    margin-left: auto;
    background-color: $background-color;
    color: $divider-color;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }
}

.files {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.ppTable {
    flex: 1;
    display: flex;
    justify-content: center; /* Centers the content horizontally */
    align-items: center; /* Centers the content vertically */
    text-align: center;
    flex-direction: column;

    nav {
        justify-content: center;
    }
}

.backButton {
    color: #5b9bd4;
    text-decoration: none;
    text-decoration: underline;
}

.backButton:hover {
    color: #567d9e;
    text-decoration: none;
    text-decoration: underline;
}


.tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // margin-bottom: 10px;
    // padding: $header-padding;
}

.mainContent {
    @include page-content;
    display: flex;
    flex-direction: column;
}

.heading {
    color: $button-bg-color;
}
