@import '../../styles/colors';
@import '../../styles/layout';
@import '../../styles/dataTable';

.mainContainer {
  @include page-content;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.pagination {
  display: flex;
  flex-direction: row;
  // gap: 15px;
  margin-left: auto;
  align-items: center;
  color: $text-color-light;

  button {
    // padding: 5px;
    display: flex;
    border-radius: 50%;
    border: none;
    width: 2rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
    background-color: #00000000;
    transition: background-color 200ms;
    &:not(:disabled) {
      color: $text-color-light;
    }
    &:hover:not(:disabled) {
      background-color: rgba($divider-color, 0.5);
      cursor: pointer;
    }
  }
}

.pageNumber {
  padding: 0px 10px;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  // gap: 10px;
}

.tableWrapper {
  @include data-table;
}

.noDataContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  color: $text-color;

  button {
    background-color: $background-color;
    color: $primary-color;
    border: none;
    padding: 8px 10px;
    border-radius: 3px;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.segmentSelection {
  select {
    font-size: medium;
    border: 2px solid $divider-color;
    border-radius: 5px;
    padding: 8px 25px 8px 5px;
    color: $text-color;
    &:focus {
      outline: none;
    }
  }
}

.jsonCell {
  font-family: monospace;
  background-color: rgba($divider-color, 0.07);
  padding: 3px;
  border-radius: 5px;
  border: 1px solid $divider-color;
  color: $primary-color;
  position: relative;
  overflow: scroll;
  white-space: prewrap;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
}

.jsonDisplay {
  background-color: rgba($divider-color, 0.4);
  color: $text-color;
  flex: 1;
  white-space: pre;
  overflow: auto;
  max-height: 400px;
  margin-top: 10px;
  padding: 10px;
  border-radius: 5px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: $background-color;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $divider-color;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: darken($divider-color, 8%);
  }
}
