@import '../../styles/colors';
@import '../../styles/dataTable';

.listComponentContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.searchBarContainer {
  display: flex;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: white;
  padding: 0px 0px 10px 0px;
}

.tableWrapper {
  @include data-table;
}

.searchBar {
  border: 1px solid #5b9bd4;
  border-radius: 20px;
  padding: 5px 10px;
  input {
    border: none;
    padding-left: 10px;
    &:focus {
      outline: none;
    }
  }
}

.fullSize {
  margin: 0px 15px;
  .searchBar {
    width: 100%;
    display: flex;
    align-items: center;

    input {
      width: 100%;
      border: none;
    }
  }
}